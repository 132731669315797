import './../libs/smooth_div_scroll/js/jquery-ui-1.8.23.custom.min'
import './../libs/smooth_div_scroll/js/jquery.mousewheel.min'
import './../libs/smooth_div_scroll/js/jquery.kinetic'
import './../libs/smooth_div_scroll/js/jquery.smoothDivScroll-1.3'

$(document).ready(function() {

    //Checks if a touch screen
    let is_touch_screen = 'ontouchstart' in document.documentElement;

    checkTouchScreenInPanorama();

    $("#makeMeScrollable").smoothDivScroll({
        touchScrolling: true,
        manualContinuousScrolling: true,
        hotSpotScrolling: true,
        mousewheelScrolling: false,
        hotSpotScrollingStep: 1,
        visibleHotSpotBackgrounds: 'always',
    });

    setTimeout(function() {
        $("#makeMeScrollable").smoothDivScroll("recalculateScrollableArea");
    }, 500);
    setTimeout(function() {
        $("#makeMeScrollable").smoothDivScroll("recalculateScrollableArea");
    }, 1000);


    $('.js-panorama-btn')
        .on('mouseenter', handleBtnMouseenter)
        .on('mouseleave', handleBtnMouseleave)
        .on('click', handleBtnClick)
        .on('touchstart', handleBtnClick);

    $('body').on('mousedown', showPanoramaElementsFromBody);

    $('.js-stop-scroll').on('click', function(e) {
        e.preventDefault();
        $("#makeMeScrollable").smoothDivScroll("stopAutoScroll");
    });

    function handleBtnMouseenter(e) {
        e.preventDefault();
        let $district = $(e.target).closest('.js-district'),
            $colorLayer = $district.find('.js-district-color');
        $colorLayer.css('opacity', 1);
    }

    function handleBtnMouseleave(e) {
        e.preventDefault();
        let $district = $(e.target).closest('.js-district'),
            $colorLayer = $district.find('.js-district-color');
        $colorLayer.attr('style', '');
    }

    function handleBtnClick(e) {
        e.preventDefault();
        let $this = $(e.target).closest('.js-panorama-btn'),
            $district = $this.closest('.js-district'),
            $colorLayer = $district.find('.js-district-color'),
            $productsList = $district.find('.js-district-products');

        if (!$this.hasClass('active')) {
            showPanoramaElements();

            $this.addClass('active');
            $colorLayer.css('opacity', 1).addClass('active');
            $productsList.show();
            setTimeout(function() {
                moveToListProducts($productsList)
            }, 300)
            $productsList.find('.list li:not(.position-line)').each(function(index, item) {
                setTimeout(function() {
                    $(item).slideDown(100);
                }, index * 100 / 3)
            });
        } else {
            $this.removeClass('active');
            $colorLayer.removeAttr("style").removeClass('active');
            $productsList.hide();
            $productsList.find('.list li:not(.position-line)').hide();
        }

    }

    function showPanoramaElementsFromBody(e) {
        if (!$(e.target).closest('.js-district-products').length) {
            $('.js-panorama-btn').removeClass('active');
            $('.js-district-color').removeAttr("style").removeClass('active');
            $('.js-district-products').hide();
            $('.js-district-products .list li:not(.position-line)').hide();
        }
    }

    function showPanoramaElements() {
        $('.js-panorama-btn').removeClass('active');
        $('.js-district-color').removeAttr("style").removeClass('active');
        $('.js-district-products').hide();
        $('.js-district-products .list li:not(.position-line)').hide();
    }

    function checkTouchScreenInPanorama() {
        let $panorama = $("#makeMeScrollable"),
            $bottomControl = $('.js-bottom-control');

        if (is_touch_screen) {
            $panorama.addClass('touch');
            $bottomControl.addClass('touch');
        } else {
            $panorama.removeClass('touch');
            $bottomControl.removeClass('touch');
        }
    }

    function moveToListProducts($productsList) {
        let positionList = $productsList.offset(),
            windowW = $(window).outerWidth(),
            listW = $productsList.outerWidth(),
            movePixels = (positionList.left + listW) - windowW + 15;

        if (positionList.left + listW > windowW) {
            $("#makeMeScrollable").smoothDivScroll("move", movePixels);
        }
    }

});

$( window ).on("load", function() {

    setTimeout(function() {
        $('.js-panorama-loader').hide()
    }, 500);

})